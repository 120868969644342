import { render, staticRenderFns } from "./EntryPage.vue?vue&type=template&id=197ad029&"
import script from "./EntryPage.vue?vue&type=script&lang=js&"
export * from "./EntryPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports