<template>
  <div id="app">
    <Main></Main>
  </div>
</template>

<script>
import Main from "./views/Main.vue";
export default {
  name: "App",
  components: {
    Main
  }
};
</script>

<style></style>
