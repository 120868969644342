<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        <strong>eksisozluk-api-client</strong> by
        <a href="https://github.com/coluck">coluck</a>. the repo of this vue
        <a href="https://github.com/coluck/eksisozluk-api-client">app</a>.
      </p>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style></style>
