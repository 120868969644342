<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
    <!-- <left-pane></left-pane> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import LeftPane from "@/components/Debe.vue";
export default {
  name: "Home",
  components: {
    // LeftPane
  }
};
</script>
